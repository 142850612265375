import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import Swal from 'sweetalert2'
import "../../style/Contact.css";
let apiBaseURL = "https://royalluck.club:5000"

function UpcomingCommission() {
  const [tableData1, setTableData1] = useState([]);
  const [error1, setError1] = useState("");
  const [error, setError] = useState('');


  const handleFetchData = async () => {
    try {
      const response = await axios.get("https://royalluck.club:5000/user/SuperCommissionMaster");
      
      if (response.data.status === 200) {
        setTableData1(response.data.data);
        setError1("");
      } else {
        setError1("No Record Found");
        setTableData1([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError1("Error fetching data");
    }
  };

  function getNextMonday() {
    var today = new Date();
    var day = today.getDay();
    var diff = 8 - day; // Calculate difference to get to next Monday
    if (day === 0) {
        diff = 1; // If today is Sunday, next Monday is just 1 day ahead
    }
    var nextMonday = new Date(today);
    nextMonday.setDate(today.getDate() + diff); // Add difference to get next Monday
    var dd = String(nextMonday.getDate()).padStart(2, '0');
    var mm = String(nextMonday.getMonth() + 1).padStart(2, '0'); // January is 0!
    var yyyy = nextMonday.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
}

  const ViewMasterFunction = (email) => {
    axios.post(`${apiBaseURL}/user/MasterCommissionData`, { email })
      .then(response => {
        const { data } = response.data;
        const tableRows = data.map(item => {
          const { master, commission } = item;
          const commissionValue = commission[0].total_master_commission !== null ? commission[0].total_master_commission : 0;
          return `<tr><td>${master.email}</td><td>${commissionValue}</td></tr>`;
        }).join('');
  
        Swal.fire({
          icon: 'success',
          title: 'Master IDs and Commission',
          html: `<div style="background-color: white; color: blue;">
                  <table style="width:100%">
                    <tr>
                      <th>Email</th>
                      <th>Commission</th>
                    </tr>
                    ${tableRows}
                  </table>
                </div>`,
          customClass: {
            title: 'swal-title',
            content: 'swal-text',
          },
          iconHtml: '<i class="fa-solid fa-user-friends"></i>',
        });
      })
      .catch(error => {
        setError('Error retrieving IDs. Please try again.');
      });
  };
  

  const columns = [
    { title: "Serial No", render: (rowData) => rowData.tableData.id + 1 },
    { title: "SuperMasterName", field: "supermaster.email" },
    { title: "Commission Percentage", field: "supermaster.commission" },
    { title: "Point Played", field: "commission[0].points_played" },
    { title: "Total Commission of SuperMaster", field: "commission[0].total_supermaster_commission" },
    { title: "Total Commission of Master", field: "commission[0].total_master_commission" },
    { title: "",
      render: (rowData) => (
        <button className="btn btn-danger ml-2" onClick={() => ViewMasterFunction(rowData.supermaster.email)}>
          Commission of Master
        </button>
      ),
    },
  ];

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div className="card card-outline card-info">
      <div style={{ textAlign: "right", paddingRight: "20px" }}>
        <h3>Allocation Date: {getNextMonday()}</h3>
      </div>
      {error1 && <div>Error: {error1}</div>}
      <MaterialTable
        title="Commission Detail List"
        data={tableData1}
        columns={columns}
        options={{ actionsColumnIndex: -1 }}
      />
    </div>
  );
}

export default UpcomingCommission;
