import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";

import "../../style/Contact.css";
import axios from "axios";
import GameDetailsModal from './GameDetailsModal';
// import { apiBaseURL } from "../../config";
import moment from "moment";

import Swal from 'sweetalert2'
let apiBaseURL = "https://royalluck.club:5000"



function PlayersList() {
  const [data, setData] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);


  //get Agents
  const getPlayers = async () => {
    await axios
    .get(`${apiBaseURL}/user/getPlayerAdmin`)

      //.get(`${apiBaseURL}/user/getPlayer`)
      .then(function (response) {
        if (response.data.status === 200) {
          console.log(response.data, "data");
          const result = response.data.data.filter((item) => {
            return item.email !== "admin@admin.com";
          });
          setData(result);
          // setData(response.data.data);
        }
      })
      .catch(function (error) {
        // history.push("/login")
      });
  };
  const handleDelete = async (email, getPlayers) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this user?");
    if (confirmDelete) {
      await axios
        .delete(`${apiBaseURL}/user/deleteUser`, {
          data: { email: email } 
        })
        .then(function (response) {
          if (response.status === 200) {
            Swal.fire("Success", response.data.message, "success").then(() =>
              getPlayers()
            );
          } else {
            Swal.fire("Error", response.data.message, "error");
          }
        })
        .catch(function (error) {
          Swal.fire("Error", "Something went wrong", "error");
        });
    }
  };


  const handleViewDetails = (email) => {
    Swal.fire({
        title: 'Select Game',
        input: 'select',
        inputOptions: {
            'funtarget_game': 'Funtarget game',
            'roulette_game': 'Roulette game',
            'andarbahar_game': 'Andar Bahar',
            'sevenup_game': 'Sevenup',
            'triplechance_game': 'Triple Chance'
        },
        showCancelButton: true,
        inputPlaceholder: 'Select a game',
        confirmButtonText: 'Save',
        cancelButtonText: 'Cancel',
        inputValidator: (value) => {
            return new Promise((resolve) => {
                if (!value) {
                    resolve('You need to select a game');
                } else {
                    resolve();
                }
            });
        },
    }).then((result) => {
        if (result.isConfirmed) {
            const GameName = result.value;
            const playername = email;

            axios.post(`${apiBaseURL}/user/getGameRecords`, { GameName, playername })
                .then((response) => {
                    if (response.data.status === 404) {
                        Swal.fire('Error', 'Player not found in the specified game', 'error');
                        return;
                    }

                    const gameDetails = response.data.data;
                    let columns = [];

                    
                    if (GameName === 'funtarget_game') {
                        columns = [
                          { title: 'Sno.', field: 'id' },
                              { title: 'Win Amount', field: 'winpoint' },
                              { title: 'Win Number ', field: 'Win_singleNo' },
                              { title: 'Win X', field: 'winX' },
                              { title: 'Played Time', field: 'playedTime' },
                              {
                                  title: 'Final Result',
                                  field: 'winpoint',
                                  render: rowData => {
                                      if (rowData.winpoint === 0) {
                                          return 'Loss';
                                      } else {
                                          return 'Win';
                                      }
                                  }
                              }
                        ];
                    } else if (GameName === 'roulette_game') {
                        columns = [
                          { title: 'Sno.', field: 'id' },
                          { title: 'Win Amount', field: 'winpoint' },
                          { title: 'Win Number ', field: 'Win_singleNo' },
                         
                          { title: 'Played Time', field: 'playedTime' },
                          { title: 'Final Result',field: 'winpoint',  }
                        ];
                    }  else if (GameName === 'andarbahar_game') {
                      columns = [
                        { title: 'Sno.', field: 'id' },
                        { title: 'Win Amount', field: 'winpoint' },
                        { title: 'Win Number ', field: 'Win_singleNo' },
                       
                        { title: 'Played Time', field: 'playedTime' },
                        { title: 'Final Result',field: 'winpoint',  }
                      ];
                    }else if (GameName === 'triplechance_game') {
                      columns = [
                        { title: 'Sno.', field: 'id' },
                        { title: 'Win Amount', field: 'winpoint' },
                        { title: 'Win Number ', field: 'Win_singalNo' },
                       
                        { title: 'Played Time', field: 'playedTime' },
                        { title: 'Final Result',field: 'winpoint',  }
                      ];
                    }else if (GameName === 'sevenup_game') {
                      columns = [
                        { title: 'Sno.', field: 'id' },
                        { title: 'Win Amount', field: 'winpoint' },
                        { title: 'Win Number ', field: 'Win_finalNo' },
                       
                        { title: 'Played Time', field: 'playerTime' },
                        { title: 'Final Result',field: 'winpoint',  }
                      ];
                    }


                  
            const pageSize = 5;
            const totalPages = Math.ceil(gameDetails.length / pageSize);
            let currentPage = 1;


            const generateTableRows = (pageNumber) => {
              const startIndex = (pageNumber - 1) * pageSize;
              const endIndex = startIndex + pageSize;
              const rows = gameDetails.slice(startIndex, endIndex);
          
              return rows.map(row => {
                  let rowData = '';
                  for (let column of columns) {
                      if (column.title === 'Final Result') {
                          let isBetPlaced = false;
          
                          if (GameName === 'roulette_game') {
                              const betFields = ['straightUpVal', 'SplitVal', 'StreetVal', 'CornerVal', 'specificBetVal', 'lineVal', 'dozen1Val', 'dozen2Val', 'dozen3Val', 'column1Val', 'column2Val', 'column3Val', 'onetoEighteenVal', 'nineteentoThirtysixVal', 'evenVal', 'oddVal', 'blackVal', 'redVal'];
                              isBetPlaced = betFields.some(field => {
                                  const fieldValue = JSON.parse(row[field]);
                                  return fieldValue !== 0 && fieldValue.length > 0;
                              });
                          } else if (GameName === 'funtarget_game') {
                              const fields = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
                              isBetPlaced = fields.some(field => row[field] !== 0);
                          } else if (GameName === 'andarbahar_game') {
                             const andarBaharFields = [
                                'Card_A_amount',
                                'Card_2_amount',
                                'Card_3_amount',
                                'Card_4_amount',
                                'Card_5_amount',
                                'Card_6_amount',
                                'Card_7_amount',
                                'Card_8_amount',
                                'Card_9_amount',
                                'Card_10_amount',
                                'Card_J_amount',
                                'Card_Q_amount',
                                'Card_K_amount',
                                'Card_Heart_amount',
                                'Card_Diamond_amount',
                                'Card_Spade_amount',
                                'Card_Club_amount',
                                'Card_Red_amount',
                                'Card_Black_amount',
                                'Card_A_6_amount',
                                'Card_seven_amount',
                                'Card_8_K_amount',
                                'Card_Andhar_amount',
                                'Card_Bahar_amount'
                            ];
                        
                              isBetPlaced = andarBaharFields.some(field => row[field] !== '0' && row[field] !== 0);
                          } else if (GameName === 'triplechance_game') {
                              const betFields = ['singleNo', 'doubleNo', 'tripleNo', 'singleVal', 'doubleVal', 'tripleVal'];
                              isBetPlaced = betFields.some(field => {
                                  const fieldValue = JSON.parse(row[field]);
                                  return fieldValue !== 0 && fieldValue.length > 0;
                              });
                          } else if (GameName === 'sevenup_game') {
                            const fields = ['singleNo', 'doubleNo', 'tripleNo'];
                            isBetPlaced = fields.some(field => row[field] !== 0);
                          }
          
                          if (!isBetPlaced) {
                              rowData += '<td style="color: #008000;">Bet Not Placed!</td>';
                          } else {
                              const winLossValue = row['winpoint'] === 0 ? '<span style="color: red;">Loss!</span>' : '<span style="color: #00FF00;">Win!</span>';
                              rowData += `<td>${winLossValue}</td>`;
                          }
                      } else if (column.field === 'playedTime') {
                          const playedTime = new Date(row['playedTime']).toLocaleString();
                          rowData += `<td>${playedTime}</td>`;
                      } else {
                          rowData += `<td>${row[column.field]}</td>`;
                      }
                  }
                  return `<tr>${rowData}</tr>`;
              }).join('');
          };
          
        //     const generateTableRows = (pageNumber) => {
        //       const startIndex = (pageNumber - 1) * pageSize;
        //       const endIndex = startIndex + pageSize;
        //       const rows = gameDetails.slice(startIndex, endIndex);
          
        //       return rows.map(row => {
        //           let rowData = '';
        //           for (let column of columns) {
        //               if (column.title === 'Final Result') {
                          
        //                   if (GameName === 'roulette_game') {
        //                       const betFields = ['straightUpVal', 'SplitVal', 'StreetVal', 'CornerVal', 'specificBetVal', 'lineVal', 'dozen1Val', 'dozen2Val', 'dozen3Val', 'column1Val', 'column2Val', 'column3Val', 'onetoEighteenVal', 'nineteentoThirtysixVal', 'evenVal', 'oddVal', 'blackVal', 'redVal'];
          
                              
        //                       const isBetPlaced = betFields.some(field => {
        //                           const fieldValue = JSON.parse(row[field]);
        //                           return fieldValue !== 0 && fieldValue.length > 0;
        //                       });
          
        //                       if (!isBetPlaced) {
        //                           rowData += '<td style="color: #008000;">Bet Not Placed!</td>';
        //                       } else {
        //                           const winLossValue = row['winpoint'] === 0 ? '<span style="color: red;">Loss!</span>' : '<span style="color: #00FF00;">Win!</span>';
        //                           rowData += `<td>${winLossValue}</td>`;
        //                       }
        //                   }
                        
        //                   else if (GameName === 'funtarget_game') {
        //                       let allFieldsZero = true;
        //                       const fields = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
        //                       for (let field of fields) {
        //                           if (row[field] !== 0) {
        //                               allFieldsZero = false;
        //                               break;
        //                           }
        //                       }
          
        //                       if (allFieldsZero) {
        //                           rowData += '<td style="color: #008000;">Bet Not Placed!</td>';
        //                       } else {
        //                           let winLossValue = '';
        //                           if (row['winpoint'] === 0) {
        //                               winLossValue = '<span style="color: red;">Loss!</span>';
        //                           } else {
        //                               winLossValue = '<span style="color: #00FF00;">Win!</span>';
        //                           }
        //                           rowData += `<td>${winLossValue}</td>`;
        //                       }
        //                   } else if (GameName === 'andarbahar_game') {
        //                     const andarBaharFields = [
        //                         'Card_A_amount',
        //                         'Card_2_amount',
        //                         'Card_3_amount',
        //                         'Card_4_amount',
        //                         'Card_5_amount',
        //                         'Card_6_amount',
        //                         'Card_7_amount',
        //                         'Card_8_amount',
        //                         'Card_9_amount',
        //                         'Card_10_amount',
        //                         'Card_J_amount',
        //                         'Card_Q_amount',
        //                         'Card_K_amount',
        //                         'Card_Heart_amount',
        //                         'Card_Diamond_amount',
        //                         'Card_Spade_amount',
        //                         'Card_Club_amount',
        //                         'Card_Red_amount',
        //                         'Card_Black_amount',
        //                         'Card_A_6_amount',
        //                         'Card_seven_amount',
        //                         'Card_8_K_amount',
        //                         'Card_Andhar_amount',
        //                         'Card_Bahar_amount'
        //                     ];
                        
        //                     const isBetPlaced = andarBaharFields.some(field => {
        //                         const fieldValue = row[field];
        //                         return fieldValue !== '0' && fieldValue !== 0;
        //                     });
                        
        //                     if (!isBetPlaced) {
        //                         rowData += '<td style="color: #008000;">Bet Not Placed!</td>';
        //                     } else {
        //                         const winLossValue = row['winpoint'] === 0 ? '<span style="color: red;">Loss!</span>' : '<span style="color: #00FF00;">Win!</span>';
        //                         rowData += `<td>${winLossValue}</td>`;
        //                     }
        //                 } else if (GameName === 'triplechance_game') {
        //                   const betFields = ['singleNo', 'doubleNo', 'tripleNo', 'singleVal', 'doubleVal', 'tripleVal',];
      
                          
        //                   const isBetPlaced = betFields.some(field => {
        //                       const fieldValue = JSON.parse(row[field]);
        //                       return fieldValue !== 0 && fieldValue.length > 0;
        //                   });
      
        //                   if (!isBetPlaced) {
        //                       rowData += '<td style="color: #008000;">Bet Not Placed!</td>';
        //                   } else {
        //                       const winLossValue = row['winpoint'] === 0 ? '<span style="color: red;">Loss!</span>' : '<span style="color: #00FF00;">Win!</span>';
        //                       rowData += `<td>${winLossValue}</td>`;
        //                   }
        //               }
                        
        //             } else if (column.field === 'playedTime') {
        //                 const playedTime = new Date(row['playedTime']).toLocaleString();
        //                 rowData += `<td>${playedTime}</td>`;
        //             } else {
        //                 rowData += `<td>${row[column.field]}</td>`;
        //             }
        //         }
        //         return `<tr>${rowData}</tr>`;
        //     }).join('');
        // };
          
          
            const renderTable = () => {
              const tableHeaders = columns.map(column => `<th>${column.title}</th>`).join('');
              const tableRows = generateTableRows(currentPage);
  
              return `<div class="table-responsive"  id="table-container"</div>
              
                        <table class="table table-bordered table-striped" style="width: 95%; margin: 0 auto; background-color: white; color: black;">
                          <thead>
                            <tr>${tableHeaders}</tr>
                          </thead>
                          <tbody>${tableRows}</tbody>
                        </table>
                        <div style="text-align: center; margin-top: 10px;">
                          <button class="btn btn-primary" onclick="prevPage()">Previous</button>
                          <span style="margin: 0 10px; color: black;">Page ${currentPage} of ${totalPages}</span>

                          <button class="btn btn-primary" onclick="nextPage()">Next</button>
                        </div>
                      </div>`;
            };
  
            window.prevPage = () => {
              if (currentPage > 1) {
                currentPage--;
                document.getElementById('table-container').innerHTML = renderTable();
              }
            };
  
            window.nextPage = () => {
              if (currentPage < totalPages) {
                currentPage++;
                document.getElementById('table-container').innerHTML = renderTable();
              }
            };

            const gameNameMappings = {
              funtarget_game: "Funtarget",
              roulette_game: "Roulette",
              andarbahar_game: "Andar Bahar",
              sevenup_game: "Sevenup",
              triplechance_game: "Triple Chance"
            };
            
            const friendlyGameName = gameNameMappings[GameName] || GameName;
            
  
            Swal.fire({
              title: `<span style="color: black;">${friendlyGameName} Game Details</span>`,
              html: renderTable(),
              confirmButtonText: 'Close',
              customClass: {
                  popup: 'custom-swal-modal',
              },
              width: '70%',
              padding: '20px',
              background: 'white',
              fontSize: '16px',
          });
      })
      .catch((error) => {
          console.error('Error fetching game record:', error);
          Swal.fire('Error', 'Failed to fetch game record', 'error');
      });
}
});
};
  

  
  
  const columns = [
    { title: "Sl No.", render: (rowData) => rowData.tableData.id + 1 },
    { title: "Email", field: "email" },
    {
      title: "MasterName",
      field: "mastername",
      render: (rowData) => (rowData.mastername ? rowData.mastername : "None"),
    },
    { title: "Point", field: "point" },
    { title: "Password", field: "password" },
    {
      title: "Actions",
      render: (rowData) => (
        <div>
          <button
            className="btn btn-danger ml-2"
            onClick={() =>
              handleEdit(rowData, apiBaseURL, getPlayers, rowData.first_name)
            }
          >
            Edit
          </button>
          <button
            className="btn btn-danger ml-2"
            onClick={() => handleDelete(rowData.email, getPlayers)}
          >
            Delete
          </button>
        </div>
      ),
    },
    {
      title: "View Details",
      field: "id", 
      render: (rowData) => (
        <button
          className="btn btn-primary"
          onClick={() => handleViewDetails(rowData.email)}
        >
          View Details
        </button>
      ),
    },
  ];
  




  useEffect(() => {
    getPlayers();
  }, []);
  
  return (
    <div className="card card-outline card-info">
      <MaterialTable
        title="Players List"
        data={data}
        columns={columns}
        options={{
          actionsColumnIndex: -1,
          pageSize: 5, 
          pageSizeOptions: [5, 10, 50], 
          paginationType: 'normal',
        }}
      />
    

      {currentRow && (
        <EditForm
          row={currentRow}
          onClose={() => setCurrentRow(null)}
          apiBaseURL={apiBaseURL}
          getPlayers={getPlayers}
        
        />
      )}



    </div>
  );

  
}

const handleEdit = async (rowData, apiBaseURL, getPlayers, firstName) => {
  const { id, password } = rowData;
  const newPassword = prompt(
    "Enter new password (leave blank to keep current password)"
  );
  const newFirstName = prompt("Enter new first name", firstName);

  // Check if the user cancelled the prompt for the new first name or new password
  if (newFirstName === null || newPassword === null) {
    return;
  }

  axios
    .put(`${apiBaseURL}/user/updateUser`, {
      id,
      first_name: newFirstName,
      password: newPassword || null,
    })
    .then(function (response) {
      if (response.status === 200) {
        Swal.fire("Success", response.data.message, "success").then(() =>
          getPlayers()
        );
      } else {
        Swal.fire("Error", response.data.message, "error");
      }
    })
    .catch(function (error) {
      Swal.fire("Error", "Something went wrong", "error");
    });
};



function EditForm({ row, onClose, apiBaseURL, getPlayers }) {
  const [firstName, setFirstName] = useState(row.first_name);
  const [password, setPassword] = useState(row.password);

  const handleSave = async () => {
    try {
      const updatedData = {
        id: row.id,
        first_name: firstName,
        password: password,
      };
  
      await axios.put(`${apiBaseURL}/user/updateUser`, updatedData);
  
      onClose();
      getPlayers();
      alert("User updated successfully");
    } catch (error) {
      console.error(error);
      alert("Failed to update user");
    }
  };
  

  return (
    <div>
      <div>
        <label htmlFor="firstName">First Name</label>
        <input
          type="text"
          id="firstName"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div>
        <button onClick={onClose}>Cancel</button>
        <button onClick={handleSave}>Save</button>
      </div>
    </div>
  );
}



export default PlayersList;
