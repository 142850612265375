import React, { useState } from 'react';
import axios from 'axios';
import MaterialTable from 'material-table';

const apiBaseURL = "https://royalluck.club:5000";

const ViewChildId = () => {
  const [email, setEmail] = useState('');
  const [emails, setEmails] = useState([]);
  const [error, setError] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleFetchIds = () => {
    setError('');
    if (email.trim() === '') {
      setError('Email cannot be empty.');
      return;
    }

    axios.post(`${apiBaseURL}/user/getEmailsById`, { email })
      .then(response => {
        const { masteridUsers, users } = response.data.result;
        const masteridEmails = masteridUsers.map(user => ({
          email: user.email,
          password: user.password,
          point: user.point
        }));
        const userEmails = users.map(user => ({
          email: user.email,
          password: user.password,
          point: user.point
        }));
        setEmails([...masteridEmails, ...userEmails]);
      })
      .catch(error => {
        setEmails([]);
        setError('Error retrieving IDs. Please try again.');
      });
  };

  return (
    <div className="container">
      <div className="form-container">
        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
        />
        <button type="button" onClick={handleFetchIds}>
          Fetch IDs
        </button>
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div className="table-container">
        <MaterialTable
          title="Child IDs"
          columns={[
            { title: 'Email', field: 'email' },
            { title: 'Pin', field: 'password' },
            { title: 'Point', field: 'point' },
          ]}
          data={emails}
          options={{
            headerStyle: {
              backgroundColor: '#4caf50',
              color: '#fff',
            },
            pageSize: 10,
            pageSizeOptions: [10, 20, 50],
          }}
        />
      </div>
    </div>
  );
};

export default ViewChildId;
