import React, { useState, useEffect } from "react";

import "../../style/Contact.css";
  import "../points/css1/home2.css";
import axios from "axios";
import Swal from "sweetalert2";
import AppFooter from "../../components/layouts/AppFooter";

function ManagePoints() {
  let apiBaseURL = "https://royalluck.club:5000";

 // let apiBaseURL = "http://localhost:5000";
  //  let apiBaseURL = "http://localhost:5000";
  const [id, setID] = useState();
  const [destriData, setDestriData] = useState([]);
  const [iderr, setIdErr] = useState(false);
  const [passerr, setPassErr] = useState(false);
  const [amounterr, setAmountErr] = useState(false);
  const [playerData, setPlayerData] = useState(null);
  const [Name, setName] = useState(null);
  const [Point, setPoint] = useState(null);

  const agentLogin = sessionStorage.getItem("loginEmail");

  const [values, setValues] = useState({
    id: "RL",
    point: "",
    pin: "",
  });
  const [transableData, settransableData] = useState([]);
  const [transableData1, settransableData1] = useState([]);

  const [transableIdArr, settransableIdArr] = useState([]);
  const [transableIdArr1, settransableIdArr1] = useState([]);

  const [isChecked, setIsChecked] = useState([]);
  const [isChecked1, setIsChecked1] = useState([]);

  const [selectAll, setSelectAll] = useState(false);
  const [selectAll1, setSelectAll1] = useState(false);
  const [email, setEmail] = useState(null);
  const TokenData = sessionStorage.getItem("token");

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const { id, point, pin } = values;
  //   const data = {
  //     receive: id,
  //     sender: sessionStorage.getItem("loginEmail"),
  //     pin: pin,
  //     point: point,
  //   };

  //   //await fetch(`${apiBaseURL}/user/sendPointsUsers`, {
  //     await fetch(`${apiBaseURL}/user/Allplayertransferpoints`, {

  //     method: "POST",
  //     mode: "cors",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${TokenData.token}`,
  //     },
  //     redirect: "follow",
  //     referrerPolicy: "no-referrer",
  //     body: JSON.stringify(data),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.status === 200) {
  //         setValues({
  //           id: "",
  //           point: 0,
  //           pin: "",
  //         });
  //         const successMessage = document.createElement("div");
  //         successMessage.textContent = "Points transferred successfully";
  //         successMessage.style.color = "red";
  //         successMessage.id = "success-message";
  //         successMessage.style.position = "fixed";
  //         successMessage.style.top = "30%";
  //         successMessage.style.left = "50%";
  //         successMessage.style.transform = "translate(-50%, -50%)";
  //         document.body.appendChild(successMessage);

  //         setTimeout(() => {
  //           successMessage.remove();
  //           window.location.reload();
  //         }, 1000);
  //       } else {
  //         if (data.status === 401) {
  //           sessionStorage.removeItem("token");
  //           window.location.reload();
  //         } else {
  //           const errorMessage = document.createElement("div");
  //           errorMessage.id = "error-message";
  //           errorMessage.textContent = `Valid player ID entered!  ${data.message}`;
  //           errorMessage.style.color = "red";
  //           errorMessage.style.position = "fixed";
  //           errorMessage.style.top = "30px";
  //           errorMessage.style.right = "20px";
  //           document.body.appendChild(errorMessage);

  //           setTimeout(() => {
  //             errorMessage.remove();
  //           }, 10000);
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       const errorMessage = document.createElement("div");
  //       errorMessage.id = "error-message";
  //       errorMessage.textContent = "Valid player ID entered!";
  //       errorMessage.style.color = "red";
  //       errorMessage.style.position = "fixed";
  //       errorMessage.style.top = "20px";
  //       errorMessage.style.right = "20px";
  //       document.body.appendChild(errorMessage);

  //       setTimeout(() => {
  //         errorMessage.remove();
  //       }, 1700);
  //     });
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { id, point, pin } = values;
    const data = {
        receive: id,
        sender: sessionStorage.getItem("loginEmail"),
        pin: pin,
        point: point,
    };

    try {
        const response = await axios.post(`${apiBaseURL}/user/Allplayertransferpoints`, data);
    } catch (error) {
        console.error('API Error:', error);
    }
};

  //get Agents
  const getAgents = async () => {
    await axios
      .get(`${apiBaseURL}/user/getPlayer`)
      .then(function (response) {
        if (response.data.status === 200) {
          setDestriData(response.data.data);
        }
      })
      .catch(function (error) {
        Swal.fire(`valid player ID enter!`, "error");
      });
  };

  const fetchPlayerPoint = async () => {
    let emailId = sessionStorage.getItem("loginEmail");

    try {
      const response = await axios.post(`${apiBaseURL}/user/getPlayerPoint`, {
        email: emailId,
      });

      if (response.status === 200) {
        const responseData = response.data;
        if (responseData && responseData.point !== undefined) {
          setPlayerData(responseData.point);
          console.log("playerData updated:", responseData.point);
        } else {
          console.log("Point data not found in response:", responseData);
        }
      } else {
        console.log("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching player point:", error);
    }
  };

 //get supermaster master user Name
  const getName= async () => {
    let emailId = sessionStorage.getItem("loginEmail");

    try {
      const response = await axios.post(`${apiBaseURL}/user/getSuperMasterUserName`, {
        email: emailId,
      });

      if (response.status === 200) {
        const responseData = response.data;
        if (responseData && responseData.Name !== undefined) {
          setName(responseData.Name);
          console.log("playerData updated:", responseData.Name);
        } else {
          console.log("Point data not found in response:", responseData);
        }
      } else {
        console.log("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching player point:", error);
    }
  };

// get all point

const getPoint= async () => {
  let emailId = sessionStorage.getItem("loginEmail");

  try {
    const response = await axios.post(`${apiBaseURL}/user/getSuperMasterUserPoint`, {
      email: emailId,
    });

    if (response.status === 200) {
      const responseData = response.data;
      if (responseData && responseData.Point !== undefined) {
        setPoint(responseData.Point);
        console.log("playerData updated:", responseData.Point);
      } else {
        console.log("Point data not found in response:", responseData);
      }
    } else {
      console.log("Unexpected response status:", response.status);
    }
  } catch (error) {
    console.error("Error fetching player point:", error);
  }
};



  const gettransable = async () => {
    let emailId = sessionStorage.getItem("loginEmail");
    await axios
      .post(`${apiBaseURL}/user/transfer`, {
        emailId: emailId,
      })
      .then(function (response) {
        if (response.data.status === 200) {
          settransableData(response.data.data);
        }
      })
      .catch(function (error) {
        Swal.fire(`valid player ID enter!`, "error");
      });
  };

  const getreceviable = async () => {
    let emailId = sessionStorage.getItem("loginEmail");
    await axios
      .post(`${apiBaseURL}/user/receive`, {
        emailId: emailId,
      })
      .then(function (response) {
        if (response.data.status === 200) {
          settransableData1(response.data.data);
        }
      })
      .catch(function (error) {
        Swal.fire(`valid player ID enter!`, "error");
      });
  };

  const sendtransableId = async () => {
    await axios

      .post(`${apiBaseURL}/user/accpetPointsUser`, { id: isChecked })
      .then(function (response) {
        if (response.data.status === 200) {
          settransableIdArr(response.data.data);
        }
      })
      .catch(function (error) {
        Swal.fire(`valid player ID enter!`, "error");
      });
  };

  // const ClickOnCheckBox = (id) => {
  //   var updatedList = [...isChecked];
  //   if (updatedList.indexOf(id) == -1) {
  //     updatedList.push(id)
  //     console.log(updatedList, "updat")

  //   } else {
  //     updatedList.splice(isChecked.indexOf(id), 1);
  //     console.log(updatedList, "update222222")

  //   }
  //   setIsChecked(updatedList);
  // };
  const loginId = sessionStorage.getItem("loginEmail");
  const ClickOnCheckBox = (id) => {
    var updatedList = [...isChecked];
    if (updatedList.indexOf(id) === -1) {
      updatedList.push(id);
    } else {
      updatedList = updatedList.filter((itemId) => itemId !== id);
    }
    setIsChecked(updatedList);
  };

  const sendreceviableId = async () => {
    await axios

      .post(`${apiBaseURL}/user/DeleteUpdate`, { id: isChecked1 })
      .then(function (response) {
        if (response.data.status === 200) {
          settransableIdArr1(response.data.data);
        }
      })
      .catch(function (error) {
        Swal.fire(`valid player ID enter!`, "error");
      });
  };

  const rejectPoints = async () => {
    try {
      await axios.post(`${apiBaseURL}/user/rejectPoint`, { id: isChecked1 });

      console.log("Points rejected successfully!");
    } catch (error) {
      console.error("Error rejecting points:", error);
    }
  };

  const cancelTransfer = async () => {
    try {
      await axios.post(`${apiBaseURL}/user/cancelTransferableId`, {
        id: isChecked,
      });

      console.log("Points rejected successfully!");
    } catch (error) {
      console.error("Error rejecting points:", error);
    }
  };

  // const ClickOnCheckBox1 = (id) => {
  //   var updatedList = [...isChecked1];
  //   if (updatedList.indexOf(id) == -1) {
  //     updatedList.push(id)
  //     console.log(updatedList, "updat")

  //   } else {
  //     updatedList.splice(isChecked1.indexOf(id), 1);
  //     console.log(updatedList, "update222222")

  //   }
  //   setIsChecked1(updatedList);
  // };

  const ClickOnCheckBox1 = (id) => {
    var updatedList = [...isChecked1];
    if (updatedList.indexOf(id) === -1) {
      updatedList.push(id);
    } else {
      updatedList.splice(updatedList.indexOf(id), 1);
    }
    setIsChecked1(updatedList);
  };

  const handleTransferPoints = async () => {
    try {
      const sender = agentLogin;
      const receive = document.getElementById("toAccNo").value;
      const point = document.getElementById("amount").value;
      const pin = document.getElementById("yourPin").value;

      // const response = await axios.post(`${apiBaseURL}/user/login`, {
      const response = await axios.post(`${apiBaseURL}/user/login`, {
        sender,
        receive,
        point,
        pin,
      });

      if (response.status === 200) {
        console.log("Points transferred successfully");
      } else {
        console.error("Points transfer failed:", response.data.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // const handleChange = (name) => (e) => {
  //   let item = e.target.value;
  //   setValues({ ...values, [name]: item });
  //   if (item.length < 3) {
  //     setIdErr(true);
  //   } else {
  //     setIdErr(false);
  //   }

  //   if (item.length < 3) {
  //     setPassErr(true);
  //   } else {
  //     setPassErr(false);
  //   }
  //   if (item.length < 1) {
  //     setAmountErr(true);
  //   } else {
  //     setAmountErr(false);
  //   }
  // };
  const handleChange = (name) => async (e) => {
    let item = e.target.value;
    setValues({ ...values, [name]: item });
    if (name === 'id') {
        try {
            const response = await axios.post(`${apiBaseURL}/user/checkAccount`, { id: item });
            if (response.data.exists) {
                setIdErr(false); 
            } else {
                setIdErr(true); 
            }
        } catch (error) {
            console.error('API Error:', error);
          
        }
    }
};

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedList = selectAll ? [] : transableData.map((item) => item.id);
    setIsChecked(updatedList);
  };

  const handleSelectAll1 = () => {
    if (selectAll1) {
      setIsChecked1([]);
    } else {
      const allIds = transableData1.map((item) => item.id);
      setIsChecked1(allIds);
    }
    setSelectAll1(!selectAll1);
  };

  useEffect(() => {
    fetchPlayerPoint();
    getPoint();
    getName();
   // getAgents();
    getreceviable();
    console.log(sessionStorage.getItem("loginEmail"));
    gettransable();
    //sendtransableId();
  }, [transableIdArr]);
  const refresh = () => window.location.reload(true);

  const handleReceiveClick = () => {
    sendreceviableId().then(() => {
      window.location.reload();
    });
  };
  
  const handleRejectClick = () => {
    rejectPoints().then(() => {
      window.location.reload();
    });
  };
  
  const handleTransferClick = () => {
    sendtransableId().then(() => {
      window.location.reload();
    });
  };
  
  const handleCancelClick = () => {
    cancelTransfer().then(() => {
      window.location.reload();
    });
  };
  

  return (
    <>
      <div className="homepage" >
        <div class="row justify-content-between" id="div1">
  
        </div>

        <div class="row" id="cl1">

          <div class="col-6 " id="col3">
            <div className="hometop">
            <h5>Manage Points</h5>
           
              <div className="col-12" id="homecenter">
                <div className="row">






                  <div class="col-6 my-2 " id="center1">
                    <div className="row" id="row1">
                      <div class="col-6 ">
                        <h6 style={{ color: 'black' }}>Receivable</h6>
                      </div>
                      <div class="col-6" id="homebtn3">
                        <button
                          className="btn1  btn-info"
                          onClick={refresh}
                          id="home3btn1"
                        >
                          Refresh
                        </button>
                      </div>
                    </div>

                    <div className="row" id="hometable">
                      <div className="example">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col" id="hometh">
                                #
                              </th>
                              <th scope="col" id="hometh">
                                From
                              </th>
                              <th scope="col" id="hometh">
                                To
                              </th>
                              <th scope="col" id="hometh">
                                Point
                              </th>
                            </tr>
                          </thead>
                          <tbody id="Homebody">
                            {transableData1.map((item, index) => {
                              return (
                                <tr id="hometr" key={item.id}>
                                  <td>
                                    {" "}
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={item.id}
                                        onChange={() =>
                                          ClickOnCheckBox1(item.id)
                                        }
                                        checked={
                                          isChecked1.includes(item.id) ||
                                          selectAll1
                                        }
                                        id="homecheck"
                                      />
                                    </div>
                                  </td>
                                  <td id="hometd" style={{ color: 'black' }}>{item.FromAccountName}</td>
                                  <td id="hometd" style={{ color: 'black' }}>{item.ToAccountName}</td>
                                  <td id="hometd" style={{ color: 'black' }}>{item.point}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      className="row"
                      id="row3"
                      style={{ borderTop: "1px solid black" }}
                    >
                      <div class="col-12">
                        <div class="row">
                          <div class="col-4" id="check1">
                            <div class="form-check" id="home1check1">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                checked={selectAll1}
                                onChange={handleSelectAll1}
                                id="flexCheckChecked1"
                              />

                              <label
                                class="form-check-label"
                                for="flexCheckChecked1"
                                style={{ color: 'black' }}
                              >
                                Select All
                              </label>
                            </div>
                          </div>
                          <div class="col-4" id="homepoint">
                            <button
                              className="btn1  btn-info"
                              onClick={handleReceiveClick}
                              id="home3btn2"
                            >
                              Receive
                            </button>
                          </div>
                          <div class="col-4 " id="homereject">
                            <button
                              className="btn1 btn-info"
                              onClick={handleRejectClick}
                              id="home3btn3"
                            >
                              Reject
                            </button>
                          </div>
                        </div>
                      </div>

                      <br />
                    </div>
                  </div>






                  <div class="col-6 my-2 " id="center2">
                    <div className="row" id="row1">
                      <div class="col-6 ">
                        <h6 style={{ color: 'black' }}>Tranferable</h6>
                      </div>
                      <div class="col-6" id="homebtn4">
                        <button
                          className="btn1  btn-info"
                          id="home3btn4"
                          onClick={handleTransferClick}
                          type="submit"
                        >
                          Point&nbsp;Transfer
                        </button>
                      </div>
                    </div>

                    <div className="row" id="hometable">
                      <div className="example">
                        <table class="table">
                          <thead>
                            <tr className="hometr">
                              <th id="hometh">#</th>
                              <th id="hometh">From</th>
                              <th id="hometh">To</th>
                              <th id="hometh">Point</th>
                            </tr>
                          </thead>

                          <tbody>
                            {}
                            {transableData.map((item, index) => {
                              return (
                                <tr key={item.id}>
                                  <td>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        id="homecheck"
                                        type="checkbox"
                                        value={item.id}
                                        onChange={() =>
                                          ClickOnCheckBox(item.id)
                                        }
                                        checked={
                                          isChecked.includes(item.id) ||
                                          selectAll
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td id="hometd" style={{ color: 'black' }}>{item.FromAccountName}</td>
                                  <td id="hometd" style={{ color: 'black' }}>{item.ToAccountName}</td>
                                  <td id="hometd" style={{ color: 'black' }}>{item.point}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      className="row"
                      id="row3"
                      style={{ borderTop: "1px solid black" }}
                    >
                      <div class="col" id="check2">
                        <div class="form-check" id="home1check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                            id="flexCheckChecked2"
                          />

                          <label
                            class="form-check-label"
                            for="flexCheckChecked2"
                            style={{ color: 'black' }}
                          >
                            Select All
                          </label>
                        </div>
                      </div>
                      <div class="col " id="homecan">
                        <button
                          id="home3btn5"
                          className="btn1 btn-info"
                          style={{ marginTop: "10px" }}
                          onClick={handleCancelClick}
                        >
                          Cancel
                        </button>
                      </div>
                      <br />
                    </div>
                  </div>


                </div>
              </div>
            </div>

            <div
              className="row"
              id="last2"
              // style={{
              //   borderTop: "2px solid black",
              // }}
            >
              {/* <h6 className="h6">:: Point Transfer</h6>
              <div className="col-12 ">
                <form
                  method="post"
                  onSubmit={handleSubmit}
                  className="row needs-validation"
                >
                  <table id="last">
                    <tbody id="home1body">
                      <tr id="hometr1">
                        <td htmlFor="toAccNo" id="t1" className="form-label">
                          To&nbsp;Account&nbsp;No
                        </td>
                        <td>
                          <input
                            type="text"
                            id="toAccNo"
                            name="distributor_id"
                            value={values.id}
                            onChange={handleChange("id")}
                          />
                          {iderr ? (
                            <span style={{ color: "red" }} id="homeerr">
                              Please provide a valid ID
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                      <tr id="hometr2">
                        <td htmlFor="yourPin" id="t1" style={{ color: 'black' }}>
                          Your&nbsp;Pin
                        </td>
                        <td>
                          <input
                            type="password"
                            id="yourPin"
                            name="passcode"
                            value={values.passcode}
                            onChange={handleChange("pin")}
                          />
                          {passerr ? (
                            <span style={{ color: "red" }} id="homeerr">
                              Please provide a valid password
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                      <tr id="hometr3">
                        <td htmlFor="amount" id="t1" style={{ color: 'black' }}>
                          Amount
                        </td>
                        <td id="amount1">
                          <input
                            type="number"
                            id="amount"
                            value={values.point}
                            onChange={handleChange("point")}
                            name="points"
                          />
                          {amounterr ? (
                            <span style={{ color: "red" }} id="homeerr">
                              Please provide a valid Amount
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                      <div className="col-12" id="btn5">
                        <button
                          className="btn1 btn-info"
                          id="btn6"
                          type="submit"
                        >
                          Transfer
                        </button>

                        <button
                          type="button"
                          className="btn btn-link"
                          id="btn7"
                        >
                          Close
                        </button>
                      </div>
                    </tbody>
                  </table>
                </form>
              </div> */}
<h6 className="h6">:: Point Transfer</h6>
<div className="col-12">
  <form method="post" onSubmit={handleSubmit} className="row needs-validation">
    <table id="last">
      <tbody id="home1body">
        {/* <tr id="hometr1">
          <td htmlFor="toAccNo" id="t1" className="form-label">
            To&nbsp;Account&nbsp;No
          </td>
          <td>
            <input
              type="text"
              id="toAccNo"
              name="distributor_id"
              value={values.id}
              onChange={handleChange("id")}
            />
          </td>
        </tr> */}
          <tr id="hometr1">
              <td htmlFor="toAccNo" id="t1" className="form-label">
                To&nbsp;Account&nbsp;No
              </td>
              <td>
                <input
                  type="text"
                  id="toAccNo"
                  name="distributor_id"
                  value={values.id}
                  onChange={handleChange("id")}
                />
                {iderr && (
                  <span style={{ color: "red" }} id="homeerr">
                    Please provide a valid ID 
                  </span>
                )}
              </td>
            </tr>
        <tr id="hometr2">
          <td htmlFor="yourPin" id="t1" style={{ color: 'black' }}>
            Your&nbsp;Pin
          </td>
          <td>
            <input
              type="password"
              id="yourPin"
              name="passcode"
              value={values.passcode}
              onChange={handleChange("pin")}
            />
          </td>
        </tr>
        <tr id="hometr3">
          <td htmlFor="amount" id="t1" style={{ color: 'black' }}>
            Amount
          </td>
          <td id="amount1">
            <input
              type="number"
              id="amount"
              value={values.point}
              onChange={handleChange("point")}
              name="points"
            />
          </td>
        </tr>
        <div className="col-12" id="btn5">
          {/* <button className="btn1 btn-info" id="btn6" type="submit">
            Transfer
          </button> */}
 <button
  className="btn1 btn-info"
  id="btn6"
  type="submit"
  disabled={iderr}
  onClick={() => {
   
    Swal.fire({
      icon: 'success',
      title: 'Point Transferred!',
      showConfirmButton: false,
      timer: 1500
    });

    // Reload after 500 milliseconds (0.5 seconds)
    setTimeout(() => window.location.reload(), 1000);
  }}
>
  Transfer
</button>


          <button type="button" className="btn btn-link" id="btn7">
            Close
          </button>
        </div>
      </tbody>
    </table>
  </form>
</div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ManagePoints;
